@import "const.scss";


// specific defined
section {
     &#keyvisual {
          .banner-hero {
               img {
                    position: absolute;
                    display: block;
                    right: 0;
                    width:auto;
                    min-width: 100%;
                    top:-150px;
               }
               .home-tag {
                    img {
                         width:100%;
                    }
                    position: absolute;

                    max-width: 350px;
                    min-width: 150px;
                    width: 25vw;
                    margin-top:800px;
                    right:150px;


               }
               min-height: 760px;

          }

     }
}


@media (max-width : $desktop-size) {
     section {
          &#keyvisual {
               .banner-hero {
                    img {
                         margin-left: -300px;
                    }
               }
          }
     }
}

@media (max-width : $tablet-size) {

     section {
          &#keyvisual {
               .banner-hero {
                    img {
                         margin-left: -130vw;
                         position: unset;
                         right: unset;
                         width: unset;
                         height: 100%;
                         min-width: unset;
                    }
                    .home-tag {
                         position: absolute;

                         max-width: 350px;
                         min-width: 150px;
                         width: 25vw;
                         margin-top:-100px;
                         right:150px;
                    }
               }

          }

     }
}

@media (max-width : $phablet-size) {

     section {
          &#keyvisual {
               .banner-hero {
                    img {
                         margin-left: -300vw;
                         position: unset;
                         right: unset;
                         width: unset;
                         height: auto;
                         min-width: unset;

                    }
               }

          }

     }


}

@media (max-width : $mobile-size) {}
